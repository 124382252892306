/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';


// const user = atob(localStorage.getItem('loggedUser'));
// const userData = JSON.parse(user);

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];

export const createDefaultNavigation = (userData: any): FuseNavigationItem[] => [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];
export const createCompactNavigation = (userData: any): FuseNavigationItem[] => [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/dashboard',
    },
    {
        id   : 'users',
        title: 'Usuarios',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/users',
        hidden: () => {
            return !userData || !userData.permissions.includes('LIST_USERS');
        }
    },
    {
        id   : 'organizations',
        title: 'Organizations',
        type : 'basic',
        icon : 'heroicons_outline:squares-2x2',
        link : '/organizations',
        hidden: () => {
            return !userData || !userData.permissions.includes('READ_ORGANIZATION');
        }
    },    
    {
        id   : 'operations',
        title: 'Operations',
        type : 'basic',
        icon : 'heroicons_outline:clipboard-document-check',
        link : '/operations',
        hidden: () => {
            return !userData || !userData.permissions.includes('READ_OPERATION');
        }
    },
    {
        id   : 'channels',
        title: 'Channels',
        type : 'basic',
        icon : 'mat_outline:dns',
        link : '/channels',
        hidden: () => {
            return !userData || !userData.permissions.includes('READ_CHANNEL');
        }
    },
    {
        id   : 'profile',
        title: 'Profile',
        type : 'basic',
        icon : 'mat_outline:account_circle',
        link : '/profile'
    },
    {
        id   : 'synthetic-profile',
        title: 'Synthetic profile',
        type : 'basic',
        icon : 'mat_outline:domain',
        link : '/synthetic-profile',
        hidden: () => {
            return !userData || !userData.permissions.includes('READ_SYNTHETIC_PROFILE');
        }
    },
    {
        id   : 'queue',
        title: 'Queue',
        type : 'basic',
        icon : 'mat_outline:query_builder',
        link : '/queue',
        hidden: () => {
            return !userData || !userData.permissions.includes('READ_QUEUE');
        }
    },
    {
        id   : 'sensia',
        title: 'Sensia',
        type : 'basic',
        icon : 'mat_outline:apps',
        link : '/sensia',
        hidden: () => {
            return !userData || !userData.permissions.includes('MANAGE_SCHEMAS');
        }
    },
    {
        id   : 'sensia-audit',
        title: 'Sensia audits',
        type : 'basic',
        icon : 'heroicons_outline:shield-check',
        link : '/sensia-audit',
        hidden: () => {
            return !userData || !userData.permissions.includes('DO_AUDITS');
        }
    }       
];
export const createFuturisticNavigation = (userData: any): FuseNavigationItem[] => [
    {
        id   : 'dashboard',
        title: 'Example3',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];
export const createHorizontalNavigation = (userData: any): FuseNavigationItem[] => [
    {
        id   : 'dashboard',
        title: 'Example4',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];
