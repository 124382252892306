import {
    Injectable
} from "@angular/core";
import {
    HttpClient
} from "@angular/common/http";
import {
    Observable
} from 'rxjs';
import {
    environment
} from "environments/environment";

@Injectable()
export class CommonService {

    constructor(private _httpClient: HttpClient) {}

    getCountries(): Observable < any > {
      return this._httpClient.get(`${environment.apiUrl}/operations/countries`);
    }

    getTemplates(): Observable < any > {
        return this._httpClient.get(`${environment.apiUrl}/templates`);
      }

}
