import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { SearchMockApi } from 'app/mock-api/common/search/api';
import { AnalyticsMockApi } from 'app/mock-api/dashboards/analytics/api';

export const mockApiServices = [
    AnalyticsMockApi,
    NavigationMockApi,
    NotificationsMockApi,
    SearchMockApi,
];
