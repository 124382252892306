import {
  initialUserState
} from "../states/user.state";
// import * as user from "../actions/user.actions";
import { createReducer } from "@ngrx/store";

export const userReducer = createReducer(
  initialUserState,
  // on(user.loadingUsers, (state) => ({ ...state, loading: true })),
  // on(user.loadUsersSuccess, (state, { response }) =>
  //   userAdapter.setAll(response.data, {
  //     ...state,
  //     error: false,
  //     loading: false,
  //     total: response.total,
  //   })
  // ),
  // on(user.loadUsersFailure, (state) =>
  //   userAdapter.removeAll({
  //     ...state,
  //     error: true,
  //     loading: false,
  //     total: 0,
  //   })
  // )
);
