import {
    HttpClient,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpRequest,
    HttpParams,
} from '@angular/common/http';
import {
    inject,
    Injectable
} from '@angular/core';
import {
    AuthUtils
} from 'app/core/auth/auth.utils';
import {
    UserService
} from 'app/core/user/user.service';
import {
    catchError,
    Observable,
    of ,
    switchMap,
    throwError
} from 'rxjs';
import { environment } from './../../environments/environment';
import { SchemaResponse } from 'app/core/models/schema-response';
import { delay, map } from 'rxjs/operators';
import { AuditResponse } from 'app/core/models/audit-response';
import { Audit } from 'app/core/models/audit';
import { QueueResponse } from 'app/core/models/queue-response';

@Injectable({
    providedIn: 'root'
})
export class SensiaService {
    private _httpClient = inject(HttpClient);
    private readonly API_URL = environment.apiUrl;

    public getSchemas(filter: string, sortDirection: 'asc' | 'desc' | '', sortField: string, 
            pageIndex: number, pageSize: number, activeFilters: Object[]): Observable<SchemaResponse> {
        // seteamos los filters
        let allStringFilterAux = [];
        activeFilters.forEach((item, index) => {
            if (item['value'] != null && item['value'] != '') {
                let stringFilterAux = [item['id'],item['value']];
                allStringFilterAux.push(stringFilterAux.join('::'));
            }
        });

        let queryFilter = allStringFilterAux.join('--');

        // Seteamos los params
        let params = new HttpParams()
        .set('page', pageIndex.toString())
        .set('page_size', pageSize.toString())
        .set('sf', sortField?.toString())
        .set('s', sortDirection?.toString().toUpperCase())
        .set('f', queryFilter);

        return this._httpClient.get(`${environment.apiUrl}/sensia/schemas`, {
        params: params
        }).pipe(
        // map(res =>  res["data"])
        map(res =>  <SchemaResponse>res)
        );    
        // return of(this.getFakeSchemas(params)).pipe(delay(3000));
    }
    
    createSchema(schema: any): Observable < any > {
        return this._httpClient.post(`${this.API_URL}/sensia/schemas`, schema);
    }

    editSchema(schema: any, id: string): Observable < any > {
        return this._httpClient.put(`${this.API_URL}/sensia/schemas/${id}`, schema);
    }

    deleteSchema(id: string): Observable < any > {
        return this._httpClient.delete(`${this.API_URL}/sensia/schemas/${id}`);
    }

    getSchema(id: string): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/sensia/schemas/${id}`);
    }

    validateSchema(idSchema: string): Observable < any > {
        return this._httpClient.post(`${this.API_URL}/sensia/schemas/${idSchema}/validate`, {});
    }

    // getSchemaCategories(id: string): Observable < any > {
    //     return this._httpClient.get(`${this.API_URL}/sensia/schemas/${id}/categories`);
    // }

    public getSchemaCategories(id: string, filter: string, sortDirection: 'asc' | 'desc' | '', sortField: string, 
    pageIndex: number, pageSize: number, activeFilters: Object[]): Observable<SchemaResponse> {
// seteamos los filters
        let allStringFilterAux = [];
        activeFilters.forEach((item, index) => {
            if (item['value'] != null && item['value'] != '') {
                let stringFilterAux = [item['id'],item['value']];
                allStringFilterAux.push(stringFilterAux.join('::'));
            }
        });

        let queryFilter = allStringFilterAux.join('--');

        // Seteamos los params
        let params = new HttpParams()
        .set('page', pageIndex.toString())
        .set('page_size', pageSize.toString())
        .set('sf', sortField?.toString())
        .set('s', sortDirection?.toString().toUpperCase())
        .set('f', queryFilter);

        return this._httpClient.get(`${this.API_URL}/sensia/schemas/${id}/categories`, {
        params: params
        }).pipe(
        // map(res =>  res["data"])
        map(res =>  <SchemaResponse>res)
        );    
        // return of(this.getFakeSchemas(params)).pipe(delay(3000));
    }


    createCategory(idSchema: string, category: any): Observable < any > {
        return this._httpClient.post(`${environment.apiUrl}/sensia/schemas/${idSchema}/categories`, category);
    }
    
    editCategory(idSchema: string, category: any, id: string): Observable < any > {
        return this._httpClient.put(`${environment.apiUrl}/sensia/schemas/${idSchema}/categories/${id}`, category);
    }

    deleteCategory(idSchema, id: string): Observable < any > {
        return this._httpClient.delete(`${environment.apiUrl}/sensia/schemas/${idSchema}/categories/${id}`);
    }

    getCategory(idSchema: string, id: string): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/sensia/schemas/${idSchema}/categories/${id}`);
    }

    validateCategory(idSchema: string, idCategory: string): Observable < any > {
        return this._httpClient.post(`${environment.apiUrl}/sensia/schemas/${idSchema}/categories/${idCategory}/validate`, {});
    }

    // getSchemaCategories(id: string): Observable < any > {
    //     return this._httpClient.get(`${this.API_URL}/sensia/schemas/${id}/categories`);
    // }

    public getSchemaSubcategories(id: string, idCateg: string, filter: string, sortDirection: 'asc' | 'desc' | '', sortField: string, 
    pageIndex: number, pageSize: number, activeFilters: Object[]): Observable<SchemaResponse> {
// seteamos los filters
        let allStringFilterAux = [];
        activeFilters.forEach((item, index) => {
            if (item['value'] != null && item['value'] != '') {
                let stringFilterAux = [item['id'],item['value']];
                allStringFilterAux.push(stringFilterAux.join('::'));
            }
        });

        let queryFilter = allStringFilterAux.join('--');

        // Seteamos los params
        let params = new HttpParams()
        .set('page', pageIndex.toString())
        .set('page_size', pageSize.toString())
        .set('sf', sortField?.toString())
        .set('s', sortDirection?.toString().toUpperCase())
        .set('f', queryFilter);

        return this._httpClient.get(`${this.API_URL}/sensia/schemas/${id}/subcategories?category_id=${idCateg}`, {
        params: params
        }).pipe(
        // map(res =>  res["data"])
        map(res =>  <SchemaResponse>res)
        );    
        // return of(this.getFakeSchemas(params)).pipe(delay(3000));
    }


    createSubcategory(idSchema: string, idCateg: string, subcategory: any): Observable < any > {
        subcategory['category_id'] = idCateg;
        return this._httpClient.post(`${environment.apiUrl}/sensia/schemas/${idSchema}/subcategories`, subcategory);
    }
    
    editSubcategory(idSchema: string, idCateg: string, subcategory: any, id: string): Observable < any > {
        subcategory['category_id'] = idCateg;
        return this._httpClient.put(`${environment.apiUrl}/sensia/schemas/${idSchema}/subcategories/${id}`, subcategory);
    }

    deleteSubcategory(idSchema, idCateg: string, id: string): Observable < any > {
        return this._httpClient.delete(`${environment.apiUrl}/sensia/schemas/${idSchema}/subcategories/${id}`);
    }

    getSubcategory(idSchema: string, idCateg: string, id: string): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/sensia/schemas/${idSchema}/subcategories/${id}`);
    }

    validateSubcategory(idSchema: string, idSubcategory: string): Observable < any > {
        return this._httpClient.post(`${environment.apiUrl}/sensia/schemas/${idSchema}/subcategories/${idSubcategory}/validate`, {});
    }

    public getQuestions(id: string, idCateg: string, idSubcateg: string, filter: string, sortDirection: 'asc' | 'desc' | '', sortField: string, 
    pageIndex: number, pageSize: number, activeFilters: Object[]): Observable<SchemaResponse> {
// seteamos los filters
        let allStringFilterAux = [];
        activeFilters.forEach((item, index) => {
            if (item['value'] != null && item['value'] != '') {
                let stringFilterAux = [item['id'],item['value']];
                allStringFilterAux.push(stringFilterAux.join('::'));
            }
        });

        let queryFilter = allStringFilterAux.join('--');

        // Seteamos los params
        let params = new HttpParams()
        .set('page', pageIndex.toString())
        .set('page_size', pageSize.toString())
        .set('sf', sortField?.toString())
        .set('s', sortDirection?.toString().toUpperCase())
        .set('f', queryFilter);

        return this._httpClient.get(`${this.API_URL}/sensia/schemas/${id}/questions?category_id=${idCateg}&subcategory_id=${idSubcateg}`, {
        params: params
        }).pipe(
        // map(res =>  res["data"])
        map(res =>  <SchemaResponse>res)
        );    
        // return of(this.getFakeSchemas(params)).pipe(delay(3000));
    }

    getQuestion(idSchema: string, id: string): Observable < any > {
        return this._httpClient.get(`${environment.apiUrl}/sensia/schemas/${idSchema}/questions/${id}`);
    }

    createQuestion(idSchema: string, idCateg: string, idSubcateg: string, question: any): Observable < any > {
        question['category_id'] = idCateg;
        question['subcategory_id'] = idSubcateg;
        return this._httpClient.post(`${environment.apiUrl}/sensia/schemas/${idSchema}/questions`, question);
    }
    
    editQuestion(idSchema: string, idCateg: string, idSubcateg: string, question: any, id: string): Observable < any > {
        question['category_id'] = idCateg;
        question['subcategory_id'] = idSubcateg;
        return this._httpClient.put(`${environment.apiUrl}/sensia/schemas/${idSchema}/questions/${id}`, question);
    }

    deleteQuestion(idSchema, id: string): Observable < any > {
        return this._httpClient.delete(`${environment.apiUrl}/sensia/schemas/${idSchema}/questions/${id}`);
    }

    public getAuditQueue(pageIndex: number, pageSize: number): Observable<AuditResponse> {
        // Seteamos los params
        let params = new HttpParams()
        // .set('status', 'QUEUED')
        .set('page', pageIndex.toString())
        .set('page_size', pageSize.toString());

        return this._httpClient.get(`${this.API_URL}/sensia/audit`, {
        params: params
        }).pipe(
        // map(res =>  res["data"])
        map(res =>  <AuditResponse>res)
        );    
    }

    public getQueues(pageIndex: number, pageSize: number): Observable<AuditResponse> {
        // Seteamos los params
        let params = new HttpParams()
        // .set('status', 'QUEUED')
        .set('page', pageIndex.toString())
        .set('page_size', pageSize.toString());

        return this._httpClient.get(`${this.API_URL}/sensia/queues`, {
        params: params
        }).pipe(
        // map(res =>  res["data"])
        map(res =>  <AuditResponse>res)
        );    
    }

    public getQueuesDt(filter: string, sortDirection: 'asc' | 'desc'| '', sortField: string, 
        pageIndex: number, pageSize: number, activeFilters: Object[]): Observable<QueueResponse> {
        // seteamos los filters
        let allStringFilterAux = [];
        activeFilters.forEach((item, index) => {
            if (item['value'] != null && item['value'] != '') {
                let stringFilterAux = [item['id'],item['value']];
                allStringFilterAux.push(stringFilterAux.join('::'));
            }
        });

        let queryFilter = allStringFilterAux.join('--');

        // Seteamos los params
        let params = new HttpParams()
        .set('page', pageIndex.toString())
        .set('page_size', pageSize.toString())
        .set('sf', sortField?.toString())
        .set('s', sortDirection?.toString().toUpperCase())
        .set('f', queryFilter);

        return this._httpClient.get(`${environment.apiUrl}/sensia/queues`, {
            params: params
        }).pipe(
            // map(res =>  res["data"])
            map(res =>  <QueueResponse>res)
        );
    }  

    public getAudits(filter: string, sortDirection: 'asc' | 'desc'| '', sortField: string, 
        pageIndex: number, pageSize: number, groupId: string = null, activeFilters: Object[]): Observable<AuditResponse> {
        // seteamos los filters
        let allStringFilterAux = [];
        activeFilters.forEach((item, index) => {
            if (item['value'] != null && item['value'] != '') {
                let stringFilterAux = [item['id'],item['value']];
                allStringFilterAux.push(stringFilterAux.join('::'));
            }
        });

        if(groupId != null){
            let stringFilterAux = ['groupId',groupId];
            allStringFilterAux.push(stringFilterAux.join('::'));
        }

        let queryFilter = allStringFilterAux.join('--');

        // Seteamos los params
        let params = new HttpParams()
        .set('page', pageIndex.toString())
        .set('page_size', pageSize.toString())
        .set('sf', sortField?.toString())
        .set('s', sortDirection?.toString().toUpperCase())
        .set('f', queryFilter);

        return this._httpClient.get(`${environment.apiUrl}/sensia/audit`, {
            params: params
        }).pipe(
            // map(res =>  res["data"])
            map(res =>  <AuditResponse>res)
        );
    }    

    public getAudit(idAudit: string): Observable<Audit>{
        return this._httpClient.get(`${environment.apiUrl}/sensia/audit/${idAudit}`)
            .pipe(
                map(res =>  <Audit>res)
            );
    }

    initAudit(idAudit: string): Observable < any > {
        return this._httpClient.post(`${environment.apiUrl}/sensia/audit/${idAudit}/init`,{});
    }

    public getAuditQuestionsNext(idAudit: string): Observable<Object>{
        return this._httpClient.get(`${environment.apiUrl}/sensia/audit/${idAudit}/questions/next`);
            // .pipe(
            //     map(res =>  <Audit>res)
            // );
    }

    public getAuditQuestionsBack(idAudit: string): Observable<Object>{
        return this._httpClient.get(`${environment.apiUrl}/sensia/audit/${idAudit}/questions/back`);
            // .pipe(
            //     map(res =>  <Audit>res)
            // );
    }

    public sendAuditQuestionResponse(idAudit: string, responses: Object): Observable<Object>{
        return this._httpClient.patch(`${environment.apiUrl}/sensia/audit/${idAudit}/question/response`, {responses: responses});
            // .pipe(
            //     map(res =>  <Audit>res)
            // );
    }

    getAuditStatus(): Observable < any > {
        return this._httpClient.get(`${environment.apiUrl}/sensia/audit/status`);
    }


    public getAuditQuestionById(idAudit: string, idQuestion: string): Observable<Object>{
        return this._httpClient.get(`${environment.apiUrl}/sensia/audit/${idAudit}/questions/${idQuestion}`);
            // .pipe(
            //     map(res =>  <Audit>res)
            // );
    }

    public getAuditNextQuestionById(idAudit: string, idQuestion: string): Observable<Object>{
        return this._httpClient.get(`${environment.apiUrl}/sensia/audit/${idAudit}/questions/${idQuestion}/next`);
            // .pipe(
            //     map(res =>  <Audit>res)
            // );
    }

    public getAuditPrevQuestionById(idAudit: string, idQuestion: string): Observable<Object>{
        return this._httpClient.get(`${environment.apiUrl}/sensia/audit/${idAudit}/questions/${idQuestion}/back`);
            // .pipe(
            //     map(res =>  <Audit>res)
            // );
    }
}
