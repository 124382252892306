import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountResponse } from '../core/models/account-response';
import { environment } from "environments/environment";
import { SyntheticProfile } from "app/core/interfaces/syntheticprofile.interface";

@Injectable()
export class SyntheticProfileService {

  constructor(private _httpClient: HttpClient) { }

  public getSyntheticProfiles({ sortDirection, sortField, pageIndex, pageSize, activeFilters }: { filter: string; sortDirection: 'asc' | 'desc' | ''; sortField: string; pageIndex: number; pageSize: number; activeFilters: unknown[]; }): Observable<AccountResponse> {
    // seteamos los filters
    const allStringFilterAux = [];
    activeFilters.forEach((item) => {
        if (item['value'] != null && item['value'] != '') {
            const stringFilterAux = [item['id'],item['value']];
            allStringFilterAux.push(stringFilterAux.join('::'));
        }
    });

    const queryFilter = allStringFilterAux.join('--');

    // Seteamos los params
    const params = new HttpParams()
    .set('page', pageIndex.toString())
    .set('page_size', pageSize.toString())
    .set('sf', sortField?.toString())
    .set('s', sortDirection?.toString().toUpperCase())
    .set('f', queryFilter);

    return this._httpClient.get(`${environment.apiUrl}/synthetic_profiles`, {
      params: params
      }).pipe(
          // map(res =>  res["data"])
          map(res =>  <AccountResponse>res)
      );    
    // return of(this.getFakeAccounts(params)).pipe(delay(3000));
  }


  getSyntheticStatus(): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/synthetic_profiles/status`);
  }


  getChannels(id: string): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/synthetic_profiles/${id}/chats`);
  }

  getChatsSubscribe(id: string, page_size: number = null): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/synthetic_profiles/${id}/chats?subscribe=true` + ((page_size)?`&page_size=${page_size}`:''));
  }

  getSyntheticProfile(id: string): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/synthetic_profiles/${id}`);
  }

  deleteSyntheticProfile(id: string): Observable < any > {
    return this._httpClient.delete(`${environment.apiUrl}/synthetic_profiles/${id}`);
  }

  editSyntheticProfile(organization: SyntheticProfile, id: string): Observable < any > {
    return this._httpClient.put(`${environment.apiUrl}/synthetic_profiles/${id}`, organization);
  }

  createSyntheticProfile(syntheticProfile: SyntheticProfile): Observable < any > {
    return this._httpClient.post(`${environment.apiUrl}/synthetic_profiles`, syntheticProfile);
  }

  getSyntheticInfo(id: string){
    return this._httpClient.get(`${environment.apiUrl}/synthetic_profiles/${id}/info`);
  }

  connectSyntheticProfile(id: string): Observable < any > {
    return this._httpClient.post(`${environment.apiUrl}/synthetic_profiles/${id}/connect`, id);
  }

  validateSyntheticProfile(id: string, code: object): Observable < any > {
    return this._httpClient.post(`${environment.apiUrl}/synthetic_profiles/${id}/connect/validate`, {code: code});
  }

  deleteConection(id: string): Observable < any > {
    return this._httpClient.delete(`${environment.apiUrl}/synthetic_profiles/${id}/disconnect`);
  } 

  getSyntheticChats(id: string){
    return this._httpClient.get(`${environment.apiUrl}/synthetic_profiles/${id}/chats`);
  }

  getSyntheticChatsSubscribe(id: string, page_size: number = null){
    return this._httpClient.get(`${environment.apiUrl}/synthetic_profiles/${id}/chats?subscribe=true` + ((page_size)?`&page_size=${page_size}`:''));
  }
  
}
