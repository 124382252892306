import {
    HttpClient,
    HttpParams,
} from '@angular/common/http';
import {
    inject,
    Injectable
} from '@angular/core';
import {
    Observable
} from 'rxjs';
import { environment } from '../../environments/environment';
import { DomainResponse } from 'app/core/models/domain-response';
import { map } from 'rxjs/operators';
import { Channel } from 'app/core/interfaces/channel.interface';

@Injectable({
    providedIn: 'root'
})
export class ChannelService {
    private _httpClient = inject(HttpClient);
    private readonly API_URL = environment.apiUrl;

    public getChannels(filter: string, sortDirection: 'asc' | 'desc' | '', sortField: string, 
            pageIndex: number, pageSize: number, activeFilters: unknown[]): Observable<DomainResponse> {
        // seteamos los filters
        const allStringFilterAux = [];
        activeFilters.forEach((item) => {
            if (item['value'] != null && item['value'] != '') {
                const stringFilterAux = [item['id'],item['value']];
                allStringFilterAux.push(stringFilterAux.join('::'));
            }
        });

        const queryFilter = allStringFilterAux.join('--');

        // Seteamos los params
        const params = new HttpParams()
        .set('page', pageIndex.toString())
        .set('page_size', pageSize.toString())
        .set('sf', sortField?.toString())
        .set('s', sortDirection?.toString().toUpperCase())
        .set('f', queryFilter);

        return this._httpClient.get(`${environment.apiUrl}/channels`, {
        params: params
        }).pipe(
        // map(res =>  res["data"])
        map(res =>  <DomainResponse>res)
        );    
        // return of(this.getFakeDomains(params)).pipe(delay(3000));
    }
    
    createChannel(domain: Channel): Observable < any > {
        return this._httpClient.post(`${this.API_URL}/channels`, domain);
    }

    editChannel(domain: Channel, id: string): Observable < any > {
        return this._httpClient.put(`${this.API_URL}/channels/${id}`, domain);
    }

    deleteChannel(id: string): Observable < any > {
        return this._httpClient.delete(`${this.API_URL}/channels/${id}`);
    }

    getChannel(id: string): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/channels/${id}`);
    }

    getChannelMessages(id: string, pageIndex: number = 0, pageSize: number = 10): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/channels/${id}/messages?page=${pageIndex}`);
    }
    
    getChannelsStatus(): Observable < any > {
        return this._httpClient.get(`${environment.apiUrl}/channels/status`);
    }
}
