import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectResponse } from '../core/models/project-response';
import { environment } from "environments/environment";
import { Operation } from "app/core/interfaces/operation.interface";

@Injectable()
export class OperationService {

  constructor(private _httpClient: HttpClient) { }

  public getOperations({ sortDirection, sortField, pageIndex, pageSize, activeFilters }: { filter: string; sortDirection: 'asc' | 'desc' | ''; sortField: string; pageIndex: number; pageSize: number; activeFilters: unknown[]; }): Observable<ProjectResponse> {
    // seteamos los filters
    const allStringFilterAux = [];
    activeFilters.forEach((item) => {
      if (item['value'] != null && item['value'] != '') {
        const stringFilterAux = [item['id'],item['value']];
        allStringFilterAux.push(stringFilterAux.join('::'));
      }
    });

    const queryFilter = allStringFilterAux.join('--');

    // Seteamos los params
    const params = new HttpParams()
    .set('page', pageIndex.toString())
    .set('page_size', pageSize.toString())
    .set('sf', sortField?.toString())
    .set('s', sortDirection?.toString().toUpperCase())
    .set('f', queryFilter);

    return this._httpClient.get(`${environment.apiUrl}/operations`, {
      params: params
      }).pipe(
          // map(res =>  res["data"])
          map(res =>  <ProjectResponse>res)
      );    
    // return of(this.getFakeProjects(params)).pipe(delay(3000));
  }


  getOperationProposes(): Observable < any > {
      return this._httpClient.get(`${environment.apiUrl}/operations/proposes`);
  }

  getOperationScopes(): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/operations/scopes`);
}

getOperationStatus(): Observable < any > {
  return this._httpClient.get(`${environment.apiUrl}/operations/status`);
}

getOperation(id: string): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/operations/${id}`);
}

createOperation(operation: Operation): Observable < any > {
    return this._httpClient.post(`${environment.apiUrl}/operations`, operation);
}

editOperation(operation: Operation, id: string): Observable < any > {
    return this._httpClient.put(`${environment.apiUrl}/operations/${id}`, operation);
}

deleteOperation(id: string): Observable < any > {
    return this._httpClient.delete(`${environment.apiUrl}/operations/${id}`);
} 
  
}
