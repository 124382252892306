import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserResponse } from '../core/models/user-response';
import { environment } from "environments/environment";
import { User } from "app/core/interfaces/user.interface";

@Injectable()
export class UserService {

  constructor(private _httpClient: HttpClient) { }

  public getUsers({ sortDirection, sortField, pageIndex, pageSize, activeFilters }: { filter: string; sortDirection: 'asc' | 'desc' | ''; sortField: string; pageIndex: number; pageSize: number; activeFilters: unknown[]; }): Observable<UserResponse> {
    // seteamos los filters
    const allStringFilterAux = [];
    activeFilters.forEach((item) => {
        if (item['value'] != null && item['value'] != '') {
            const stringFilterAux = [item['id'],item['value']];
            allStringFilterAux.push(stringFilterAux.join('::'));
        }
    });

    const queryFilter = allStringFilterAux.join('--');

    // Seteamos los params
    const params = new HttpParams()
    .set('page', pageIndex.toString())
    .set('page_size', pageSize.toString())
    .set('sf', sortField?.toString())
    .set('s', sortDirection?.toString().toUpperCase())
    .set('f', queryFilter);

    return this._httpClient.get(`${environment.apiUrl}/users`, {
      params: params
      }).pipe(
          // map(res =>  res["data"])
          map(res =>  <UserResponse>res)
      );    
    // return of(this.getFakeUsers(params)).pipe(delay(3000));
  }


  getUserStatus(allStatus = null): Observable < any > {
    if(allStatus) {
      return this._httpClient.get(`${environment.apiUrl}/users/status?all=true`);
    } else {
      return this._httpClient.get(`${environment.apiUrl}/users/status`);
    }
  }

  getUserRoles(): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/users/roles`);
}

  getUser(id: string): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/users/${id}`);
  }

  editUser(user: User, id: string): Observable < any > {
    return this._httpClient.put(`${environment.apiUrl}/users/${id}`, user);
  }

  createUser(user: User): Observable < any > {
    return this._httpClient.post(`${environment.apiUrl}/users`, user);
  }
  
  
}
