import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountResponse } from '../core/models/account-response';
import { environment } from "environments/environment";
import { Organization } from "app/core/interfaces/organization.interface";

@Injectable()
export class OrganizationService {

  constructor(private _httpClient: HttpClient) { }

  public getOrganizations({ sortDirection, sortField, pageIndex, pageSize, activeFilters }: { filter: string; sortDirection: 'asc' | 'desc' | ''; sortField: string; pageIndex: number; pageSize: number; activeFilters: unknown[]; }): Observable<AccountResponse> {
    // seteamos los filters
    const allStringFilterAux = [];
    activeFilters.forEach((item) => {
        if (item['value'] != null && item['value'] != '') {
            const stringFilterAux = [item['id'],item['value']];
            allStringFilterAux.push(stringFilterAux.join('::'));
        }
    });

    const queryFilter = allStringFilterAux.join('--');

    // Seteamos los params
    const params = new HttpParams()
    .set('page', pageIndex.toString())
    .set('page_size', pageSize.toString())
    .set('sf', sortField?.toString())
    .set('s', sortDirection?.toString().toUpperCase())
    .set('f', queryFilter);

    return this._httpClient.get(`${environment.apiUrl}/organizations`, {
      params: params
      }).pipe(
          // map(res =>  res["data"])
          map(res =>  <AccountResponse>res)
      );    
    // return of(this.getFakeAccounts(params)).pipe(delay(3000));
  }


  getOrganizationStatus(): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/organizations/status`);
}

getOrganizationTypes(): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/organizations/types`);
}

getOrganization(id: string): Observable < any > {
  return this._httpClient.get(`${environment.apiUrl}/organizations/${id}`);
}

deleteOrganization(id: string): Observable < any > {
  return this._httpClient.delete(`${environment.apiUrl}/organizations/${id}`);
}

editOrganization(organization: Organization, id: string): Observable < any > {
  return this._httpClient.put(`${environment.apiUrl}/organizations/${id}`, organization);
}

createOrganization(organization: Organization): Observable < any > {
  return this._httpClient.post(`${environment.apiUrl}/organizations`, organization);
}
  
}
