import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "environments/environment";

@Injectable()
export class TelegramService {

  constructor(private _httpClient: HttpClient) { }

  getMessage(id: string): Observable < any > {
      return this._httpClient.get(`${environment.apiUrl}/telegram/message/${id}`);
  }

}
