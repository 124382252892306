import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { PermissionGuard } from './core/auth/guards/permission.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'confirmation-success', loadChildren: () => import('app/modules/auth/confirmation-success/confirmation-success.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')},
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        // canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'dashboard', loadChildren: () => import('app/modules/admin/pages/dashboard/analytics/analytics.routes')},
            {path: 'operations', loadChildren: () => import('app/modules/admin/pages/operations/operations.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_OPERATION'}},
            {path: 'operations/read/:id', loadChildren: () => import('app/modules/admin/pages/operations/read-view/read-view.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_OPERATION'}},
            {path: 'operations/edit/:id', loadChildren: () => import('app/modules/admin/pages/operations/add-edit-view/add-edit-view.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'WRITE_OPERATION'}},
            {path: 'operations/create', loadChildren: () => import('app/modules/admin/pages/operations/add-edit-view/add-edit-view.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'WRITE_OPERATION'}},


            {path: 'channels', loadChildren: () => import('app/modules/admin/pages/channels/channels.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_CHANNEL' }},
            {path: 'channels/read/:id', loadChildren: () => import('app/modules/admin/pages/channels/read-view/read-view.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_CHANNEL' }},
            {path: 'channels/edit/:id', loadChildren: () => import('app/modules/admin/pages/channels/add-edit-view-channel/add-edit-view-channel.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'WRITE_CHANNEL' }},
            {path: 'channels/create', loadChildren: () => import('app/modules/admin/pages/channels/add-edit-view-channel/add-edit-view-channel.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'WRITE_CHANNEL' }},


            {path: 'organizations', loadChildren: () => import('app/modules/admin/pages/organizations/organizations.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_ORGANIZATION'}},
            {path: 'organizations/read/:id', loadChildren: () => import('app/modules/admin/pages/organizations/read/read.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_ORGANIZATION'}},
            {path: 'organizations/edit/:id', loadChildren: () => import('app/modules/admin/pages/organizations/add-edit-view/add-edit-view.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'WRITE_ORGANIZATION'}},
            {path: 'organizations/create', loadChildren: () => import('app/modules/admin/pages/organizations/add-edit-view/add-edit-view.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'WRITE_ORGANIZATION'}},

            {path: 'users', loadChildren: () => import('app/modules/admin/pages/users/users.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'LIST_USERS' }},
            {path: 'users/read/:id', loadChildren: () => import('app/modules/admin/pages/users/read/read.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'DETAIL_USERS' }},
            {path: 'users/edit/:id', loadChildren: () => import('app/modules/admin/pages/users/edit/edit.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'DETAIL_USERS' }},
            {path: 'users/create', loadChildren: () => import('app/modules/admin/pages/users/edit/edit.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'ADD_USERS' }},
            {path: 'users/me/read', loadChildren: () => import('app/modules/admin/pages/users/read/read.routes')},
            {path: 'users/me/edit', loadChildren: () => import('app/modules/admin/pages/users/edit/edit.routes')},

            {path: 'profile', loadChildren: () => import('app/modules/admin/pages/profile/profile.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_PROFILE'}},
            {path: 'profile/read/:id', loadChildren: () => import('app/modules/admin/pages/profile/read/read.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_PROFILE'}},
            {path: 'profile/edit/:id', loadChildren: () => import('app/modules/admin/pages/profile/create/create.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'WRITE_PROFILE'}},


            {path: 'synthetic-profile', loadChildren: () => import('app/modules/admin/pages/synthetic-profile/syntheticProfile.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_SYNTHETIC_PROFILE'}},
            {path: 'synthetic-profile/create', loadChildren: () => import('app/modules/admin/pages/synthetic-profile/create/create.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'WRITE_SYNTHETIC_PROFILE'}},
            {path: 'synthetic-profile/add-info/:id', loadChildren: () => import('app/modules/admin/pages/synthetic-profile/add-info/add-info.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'WRITE_SYNTHETIC_PROFILE'}},
            {path: 'synthetic-profile/edit/:id', loadChildren: () => import('app/modules/admin/pages/synthetic-profile/create/create.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'WRITE_SYNTHETIC_PROFILE'}},
            {path: 'synthetic-profile/read/:id', loadChildren: () => import('app/modules/admin/pages/synthetic-profile/read/read.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_SYNTHETIC_PROFILE'}},

            {path: 'queue', loadChildren: () => import('app/modules/admin/pages/queue/queue.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_QUEUE'}},
            {path: 'queue/create', loadChildren: () => import('app/modules/admin/pages/queue/create/create.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'WRITE_QUEUE'}},
            {path: 'queue/edit/:id', loadChildren: () => import('app/modules/admin/pages/queue/create/create.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'WRITE_QUEUE'}},
            {path: 'queue/read/:id', loadChildren: () => import('app/modules/admin/pages/queue/read/read.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'READ_QUEUE'}},

            {path: 'sensia', loadChildren: () => import('app/modules/admin/pages/sensia/sensia.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'MANAGE_SCHEMAS'}},

            {path: 'sensia-audit', loadChildren: () => import('app/modules/admin/pages/sensia-audit/sensia-audit.routes'), canActivate: [PermissionGuard], data: { requiredPermission: 'DO_AUDITS'}},

        ]
    }
];
