import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  public userData: any;

  constructor( private router: Router) {}
  

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const requiredPermission = route.data['requiredPermission'] as string;
    const user = atob(localStorage.getItem('loggedUser'));
    this.userData = JSON.parse(user);


    if (this.userData && this.userData.permissions.includes(requiredPermission)) {
      return true;
    } else {
      this.router.navigate(['/']); // O cualquier otra ruta de redirección
      return false;
    }
  }
}
